import {
    ActiveStatus,
    ChatType,
    DocumentPersistenceType,
    OperationType,
    PromptTemplateCategory,
    ModelType,
    KplOptionsType,
} from '../__generated__/graphql';
import { HEADER_LOGO_URL, KAYA_CDN_URL } from './EnvironmentConstants';

export const KAYA_ICON_BASE_URL = `${KAYA_CDN_URL}/icons/bernie-bot`;

export const IMG_KAYA_LOGO_WHITE = HEADER_LOGO_URL;
export const IMG_KAYA_BOT_GIF = `${KAYA_ICON_BASE_URL}-chatbot-icon-v2.gif`;
export const IMG_KAYA_BOT_PNG = `${KAYA_ICON_BASE_URL}-chatbot-icon-v2.png`;
export const IMG_USER_PNG = `${KAYA_CDN_URL}/icons/user-circle.svg`;
export const IMG_KAYA_POPUP_BOT = `${KAYA_ICON_BASE_URL}-chatbot-anime.gif`;
export const IMG_KAYA_POPUP_CLOSE = `${KAYA_ICON_BASE_URL}-close.png`;
export const IMG_KAYA_MIC_ON_GIF = `${KAYA_ICON_BASE_URL}-mic-on.gif`;
export const IMG_KAYA_MIC_DEFAULT_GIF = `${KAYA_ICON_BASE_URL}-mic-default.gif`;
export const IMG_KAYA_HOW_GIF = `${KAYA_ICON_BASE_URL}-how.gif`;
export const IMG_KAYA_ADD_GIF = `${KAYA_ICON_BASE_URL}-add.gif`;
export const IMG_KAYA_BUTTON_SEND_DISABLED = `${KAYA_ICON_BASE_URL}-send-icon-disabled.png`;
export const IMG_KAYA_BUTTON_SEND = `${KAYA_ICON_BASE_URL}-send-icon.png`;

export const CSS_SCROLL =
    'overflow-y-scroll overflow-x-hidden scrollbar-thumb-blue scrollbar-thumb-rounded scrollbar-track-blue-lighter scrollbar-w-2 scrolling-touch';
export const CSS_INPUT =
    'leading-[15px] resize-none focus:outline-none focus:placeholder-N-400 text-N-600 placeholder-N-600 bg-white rounded-[5px] p-2 mx-1 border-[2px] border-N-300 focus:border-[2px] focus:ring-B-500 focus:border-B-500';
export const CSS_LIST = `flex flex-col h-[calc(100vh-150px)] ${CSS_SCROLL} sm:pb-[32px]`;

export const MAX_TEXT_LENGTH = 10000;
export const MOBILE_SCREEN_QUERY = `(max-width:640px)`;
export const MOBILE_SCREEN_SIZE = 640;

export const FEEDBACK_QUESTIONS = [
    { text: "👌 How satisfied were you with Bernie's technical assistance?", type: 'RATING' },
    {
        text: '🤝 How useful was Bernie for employee engagement tasks?',
        type: 'RATING',
    },
    { text: '🔄 How likely are you to continue using Bernie in your daily tasks?', type: 'RATING' },
    { text: '🐞 Have you found any bugs in Bernie?', type: 'FILE' },
    { text: '📝 Enter your feedback here', type: 'TEXT' },
];

export const MODEL_TYPES = [
    {
        type: 'category',
        models: [
            {
                type: 'model',
                model: ModelType.OpenAiOmni,
                name: 'GPT-4o mini',
                imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.OpenAiOmni}.png`,
            },
            {
                type: 'model',
                model: ModelType.OpenAi,
                name: 'GPT-4o',
                imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.OpenAi}.png`,
            },
        ],
        name: 'Open AI',
        imageURL: `${KAYA_ICON_BASE_URL}-OPEN_AI.png`,
    },

    {
        type: 'category',
        models: [
            {
                type: 'model',
                model: ModelType.GoogleAiFlash,
                name: 'Gemini 1.5 Flash',
                imageURL: `${KAYA_ICON_BASE_URL}-GOOGLE_AI.png`,
            },
            {
                type: 'model',
                model: ModelType.GoogleAi,
                name: 'Gemini 1.5 Pro',
                imageURL: `${KAYA_ICON_BASE_URL}-GOOGLE_AI.png`,
            },
        ],
        name: 'Google',
        imageURL: `${KAYA_ICON_BASE_URL}-GOOGLE_AI.png`,
    },
    {
        type: 'category',
        models: [
            {
                type: 'model',
                model: ModelType.ClaudeHaiku,
                name: 'Claude 3.5 Haiku',
                imageURL: `${KAYA_ICON_BASE_URL}-CLAUDE.png`,
            },
            {
                type: 'model',
                model: ModelType.Claude,
                name: 'Claude 3.5 Sonnet',
                imageURL: `${KAYA_ICON_BASE_URL}-CLAUDE.png`,
            },
        ],
        name: 'Anthropic',
        imageURL: `${KAYA_ICON_BASE_URL}-CLAUDE.png`,
    },
    {
        type: 'category',
        models: [
            {
                type: 'model',
                model: ModelType.MistralSmall,
                name: 'Mistral Small',
                imageURL: `${KAYA_ICON_BASE_URL}-MISTRAL.png`,
            },
            {
                type: 'model',
                model: ModelType.Mistral,
                name: 'Mistral Large',
                imageURL: `${KAYA_ICON_BASE_URL}-MISTRAL.png`,
            },
        ],
        name: 'Mistral',
        imageURL: `${KAYA_ICON_BASE_URL}-MISTRAL.png`,
    },
    {
        type: 'model',
        model: ModelType.Cohere,
        name: 'Cohere',
        imageURL: `${KAYA_ICON_BASE_URL}-COHERE.png`,
    },
    {
        type: 'category',
        models: [
            {
                type: 'model',
                model: ModelType.GoogleGemma_2,
                name: 'Google Gemma 2',
                imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.GoogleGemma_2}.png`,
            },
            {
                type: 'model',
                model: ModelType.IbmGranite_3,
                name: 'IBM Granite 3',
                imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.IbmGranite_3}.png`,
            },
            {
                type: 'model',
                model: ModelType.MetaLlama_3_2,
                name: 'Meta Llama 3.2',
                imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.MetaLlama_3_2}.png`,
            },
            {
                type: 'model',
                model: ModelType.MicrosoftPhi_3_5,
                name: 'Microsoft Phi 3.5',
                imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.MicrosoftPhi_3_5}.png`,
            },
            {
                type: 'model',
                model: ModelType.NvidiaNemotronMini,
                name: 'NVIDIA Nemotron mini',
                imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.NvidiaNemotronMini}.png`,
            },
        ],
        name: 'SLM',
        imageURL: `${KAYA_ICON_BASE_URL}-chatbot-icon-v2.gif`,
    },
    {
        type: 'model',
        model: ModelType.Deepseek,
        name: 'Deep seek',
        imageURL: `${KAYA_ICON_BASE_URL}-${ModelType.Deepseek}.png`,
    },
];

export const DEFAULT_MODELS = MODEL_TYPES.flatMap(modelType =>
    modelType.type === 'category' && modelType.models
        ? modelType.models.map(nestedModel => nestedModel.model) // Extract model from nested models
        : modelType.model // Top-level model
).join(',');

export const TABLE_MODEL_TYPES = MODEL_TYPES.flatMap(modelType => {
    if (modelType.type === 'category' && modelType.models) {
        // For categories with nested models, map each nested model
        return modelType.models.map(nestedModel => ({
            id: nestedModel.model,
            label: nestedModel.name,
            leadingNodeType: 'avatar',
            avatarProps: {
                size: 'md',
                type: 'image',
                imageURL: nestedModel.imageURL,
            },
            isDefault: false,
            isSelected: false,
        }));
    } else {
        // For top-level models
        return {
            id: modelType.model,
            label: modelType.name,
            leadingNodeType: 'avatar',
            avatarProps: {
                size: 'md',
                type: 'image',
                imageURL: modelType.imageURL,
            },
            isDefault: false,
            isSelected: false,
        };
    }
});


export const MAPPING_MODEL_TYPES: { [key: string]: string } = MODEL_TYPES.reduce(
    (acc, item) => {
        // Check if the item has nested models (i.e., it's a 'category')
        if (item.type === 'category' && item.models) {
            item.models.forEach((model) => {
                acc[model.model] = model.name;
            });
        } else if (item.type === 'model' && item.model) {
            // Directly map if it's a 'model' type without nested models
            acc[item.model] = item.name;
        }
        return acc;
    },
    {} as { [key: string]: string }
);

export const findModelName = (model: string) => MAPPING_MODEL_TYPES[model];
export const findModelType = (name: string) => {
    for (const modelType of MODEL_TYPES) {
        // Check top-level model
        if (modelType.name === name) {
            return modelType.model;
        }

        // Check nested models if it's a category
        if (modelType.type === 'category' && modelType.models) {
            const nestedModel = modelType.models.find(nested => nested.name === name);
            if (nestedModel) {
                return nestedModel.model;
            }
        }
    }

    return undefined; // Return undefined if not found
};

export const SETTING_TYPES = [
    {
        key: KplOptionsType.DynamicPrompts,
        name: 'Dynamic Prompts',
        description: 'Enhance your query with dynamic prompts',
    },
    {
        key: KplOptionsType.WebSearch,
        name: 'Web Search',
        description: 'Enable web search for real-time information',
    },
    {
        key: KplOptionsType.NoLlm,
        name: 'No-LLM',
        description: 'Disable LLM for faster responses',
    },
];

export const DOCUMENT_PERSISTENCE_TYPES = [
    {
        type: DocumentPersistenceType.Permanent,
        name: 'Save Permanently to current KPL',
    },
    {
        type: DocumentPersistenceType.Temporary,
        name: 'Save Temporarily to current Chat',
    },
];

export const SYSTEM_MODEL_TEMP_ID = 'sys_model_temp';
export const FEATURE_MODEL_TEMP_ID = 'feature_model_temp';
export const USER_TEMP_ID = 'user_tmp';
export const SYSTEM_TEMP_ID = 'sys_temp';
export const TOTAL_TEMP_MESSAGE_TIME = 120000; // 2 minutes

export const CHAT_TYPES = [ChatType.Assistant, ChatType.Operation, ChatType.Custom];
export const KPL_STATUSES = [ActiveStatus.Enabled, ActiveStatus.Disabled];
export const PAGE_SIZE = 25;

export const OPERATION_TYPES = [OperationType.And, OperationType.Or];
export const CUSTOM_META_DATA_KEYS = ['domain', 'client', 'feature', 'source', 'file_name', 'document_name'];

export const NOT_AVAILABLE_VALUE = 'N/A';
export const ALL_VALUE = 'All';
export const ALL_VALUE_CAPITALIZED = 'ALL';
export const ALL_VALUE_KPL = '*';

export const MODEL_BASED_PROMPTS = MODEL_TYPES.flatMap(modelType => {
    // For category types, process nested models
    if (modelType.type === 'category' && modelType.models) {
        return modelType.models.map(nestedModel => {
            const modelValue: ModelType = nestedModel.model ?? ModelType.OpenAi;
            const isGoogleOrMistral =
                nestedModel.model === ModelType.GoogleAiFlash ||
                nestedModel.model === ModelType.GoogleAi ||
                nestedModel.model === ModelType.Mistral ||
                nestedModel.model === ModelType.MistralSmall;
            return {
                model: modelValue,
                prompt: `Context: You are an AI assistant named Bernie. Your primary role is to answer user inquiries using content from the knowledge hub. If responding to questions based on user-uploaded documents, rely solely on the document content.

## Guidelines

1. Primary Source: Always begin your response by referencing content from the knowledge hub. Ensure that your answers are informed by this source.
2. Fallback Strategy: If the knowledge hub lacks the necessary information, or if it provides insufficient details:
   - Provide a clear and accurate response based on available information.
   - Do not speculate or generate information beyond what is provided.
3. Response Quality: Maintain high standards of grammar and clarity. Format your responses using markdown, incorporating lists, tables, charts, links, and structured text when relevant. Avoid using markdown code blocks; instead, provide the markdown text directly.
4. Handling Insufficient Data: When the knowledge hub does not cover the user's query, deliver a concise and informative response, ensuring it is as accurate as possible with the given data.
5. Incorporate Links: Use and reference specific links from the knowledge hub in your responses whenever applicable. Make sure these links are relevant and enhance the user's understanding.
6. Content Integrity: The knowledge hub should be the exclusive source for constructing responses unless the user has uploaded a document.
7. Recent Updates: Always prioritize including the most recent information available up to the current date, unless the user specifically requests otherwise.
8. User-Uploaded Documents: When the user asks about information in an uploaded document:
   - Only use content from the uploaded document.
   - Do not reference or include knowledge hub content in your response.
   For example:
   - "Based on the document you uploaded, here is a summary of the key findings..."
   - "Analyzing the data from your uploaded document, here is a comprehensive report..."
9. Strict Adherence: Ensure you only answer the original question as asked. Do not extrapolate, assume, or add details not supported by the provided content. If the query pertains to an uploaded document, exclusively use that document's content for your response.

**Knowledge Hub Content:** {information}
**User Uploaded Document Content:** {document_content}${isGoogleOrMistral ? '\n**Conversation History:** {history}\nHuman: {query}\nAI:' : ''}`,
                variables: isGoogleOrMistral
                    ? ['information', 'document_content', 'history', 'query']
                    : ['information', 'document_content'],
                category: isGoogleOrMistral
                    ? PromptTemplateCategory.ResponsePrompt
                    : PromptTemplateCategory.ChatPrompt,
            };
        });
    }

    // For top-level models
    const modelValue: ModelType = modelType.model ?? ModelType.OpenAi;
    const isGoogleOrMistral =
        modelType.model === ModelType.GoogleAiFlash ||
        modelType.model === ModelType.GoogleAi ||
        modelType.model === ModelType.Mistral ||
        modelType.model === ModelType.MistralSmall;
    return {
        model: modelValue,
        prompt: `Context: You are an AI assistant named Bernie. Your primary role is to answer user inquiries using content from the knowledge hub. If responding to questions based on user-uploaded documents, rely solely on the document content.

## Guidelines

1. Primary Source: Always begin your response by referencing content from the knowledge hub. Ensure that your answers are informed by this source.
2. Fallback Strategy: If the knowledge hub lacks the necessary information, or if it provides insufficient details:
   - Provide a clear and accurate response based on available information.
   - Do not speculate or generate information beyond what is provided.
3. Response Quality: Maintain high standards of grammar and clarity. Format your responses using markdown, incorporating lists, tables, charts, links, and structured text when relevant. Avoid using markdown code blocks; instead, provide the markdown text directly.
4. Handling Insufficient Data: When the knowledge hub does not cover the user's query, deliver a concise and informative response, ensuring it is as accurate as possible with the given data.
5. Incorporate Links: Use and reference specific links from the knowledge hub in your responses whenever applicable. Make sure these links are relevant and enhance the user's understanding.
6. Content Integrity: The knowledge hub should be the exclusive source for constructing responses unless the user has uploaded a document.
7. Recent Updates: Always prioritize including the most recent information available up to the current date, unless the user specifically requests otherwise.
8. User-Uploaded Documents: When the user asks about information in an uploaded document:
   - Only use content from the uploaded document.
   - Do not reference or include knowledge hub content in your response.
   For example:
   - "Based on the document you uploaded, here is a summary of the key findings..."
   - "Analyzing the data from your uploaded document, here is a comprehensive report..."
9. Strict Adherence: Ensure you only answer the original question as asked. Do not extrapolate, assume, or add details not supported by the provided content. If the query pertains to an uploaded document, exclusively use that document's content for your response.

**Knowledge Hub Content:** {information}
**User Uploaded Document Content:** {document_content}${isGoogleOrMistral ? '\n**Conversation History:** {history}\nHuman: {query}\nAI:' : ''}`,
        variables: isGoogleOrMistral
            ? ['information', 'document_content', 'history', 'query']
            : ['information', 'document_content'],
        category: isGoogleOrMistral
            ? PromptTemplateCategory.ResponsePrompt
            : PromptTemplateCategory.ChatPrompt,
    };
});

export const EXTERNAL_USERS_REQUEST_STATUS: any = {
    Rejected: 'red',
    Pending: 'amber',
    Approved: 'green',
};

export const USER_STATUS_COLORS: any = {
    Inactive: 'red',
    Active: 'blue',
};

export const MAX_CHAR_LIMIT = {
    title: 200,
    shortDescription: 500,
    detailedDescription: 1000,
    predefinedQuestionTitle: 2000,
    promptName: 500,
};

export const ACCEPTED_FILE_LIST_IN_CUSTOM_KPL =
    'text/plain, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv';
