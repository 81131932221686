import { SearchInput } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { ALL_VALUE, findModelType, MODEL_TYPES, NOT_AVAILABLE_VALUE } from '../../../constants';

export interface IModelSelectProps {
    onChange: Function;
    hasEmptyOption?: boolean;
    value?: string | null;
    hasAllOption?: boolean;
    disabled?: boolean;
}

export const ModelSelect = ({ onChange, hasEmptyOption, hasAllOption, value, disabled = false }: IModelSelectProps) => {
    const [currentModel, setCurrentModel] = useState<string>(() => {
        if (value === null || value === undefined) {
            if (hasAllOption) {
                return ALL_VALUE;
            } else if (hasEmptyOption) {
                return NOT_AVAILABLE_VALUE;
            } else {
                // Default to the name of the first available model in MODEL_TYPES, whether nested or not
                const firstModel = MODEL_TYPES.find(
                    modelType => modelType.type !== 'category' || (modelType.models && modelType.models.length > 0)
                );
                return firstModel?.name || ''; // Fallback to empty string if no model is found
            }
        }
        return value;
    });

    let ALL_MODELS = MODEL_TYPES.flatMap(modelType =>
        modelType.type === 'category' && modelType.models
            ? modelType.models.map(nestedModel => nestedModel.name)
            : modelType.name
    );

    if (hasEmptyOption) {
        ALL_MODELS = [NOT_AVAILABLE_VALUE, ...ALL_MODELS];
    }

    if (hasAllOption) {
        ALL_MODELS = [ALL_VALUE, ...ALL_MODELS];
    }

    useEffect(() => {
        if (value === ALL_VALUE) {
            setCurrentModel(value);
        }

        if (value === null || value === undefined) {
            const firstModelName =
                MODEL_TYPES.find(
                    modelType => modelType.type !== 'category' || (modelType.models && modelType.models.length > 0)
                )?.name ||
                MODEL_TYPES[0].models?.[0]?.name ||
                ''; // Fallback to the first nested model's name if no top-level name exists
            setCurrentModel(hasAllOption ? ALL_VALUE : hasEmptyOption ? NOT_AVAILABLE_VALUE : firstModelName);
        }
    }, [value]);

    return (
        <SearchInput
            value={currentModel}
            onChange={(value: string) => {
                const name = value;
                const model = value === NOT_AVAILABLE_VALUE || value === ALL_VALUE ? null : findModelType(value);
                setCurrentModel(name);
                onChange({ name, model });
            }}
            placeholder="Select Model"
            view="default"
            wrapperClassName="w-full"
            items={ALL_MODELS}
            disabled={disabled}
        />
    );
};
