import React, { useState } from 'react';
import CN from 'classnames';
import { Avatar, AvatarProps, CheckBox } from '@kaya/kaya-ui-design-system-pb';

export interface ExpandableDropdownItemProps {
    avatarProps?: AvatarProps;
    className?: string;
    handleItemOnClick?: (id: number | undefined) => void;
    hasItemSeperator?: boolean;
    icon?: string;
    id: number;
    isDisabled?: boolean;
    label?: string;
    leadingNodeType?: 'icon' | 'avatar' | undefined;
    selectedItem?: number | null;
    selectionType?: 'single' | 'multiple';
    defaultChecked?: boolean;
    onClick?: (id: number | undefined) => void;
    subItems?: ExpandableDropdownItemProps[];
    readonly showSelectedCheck?: boolean;
}

export const ExpandableDropdownItem = ({
    avatarProps,
    className,
    handleItemOnClick,
    hasItemSeperator,
    icon,
    id,
    isDisabled = false,
    label,
    leadingNodeType,
    selectedItem,
    selectionType,
    defaultChecked,
    onClick,
    showSelectedCheck,
    subItems,
}: ExpandableDropdownItemProps) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const CommonClasses = CN('dropdown__item text-sm py-[8px] w-full', className);
    const DisableStateClasses = CN({
        'text-N-900 hover:bg-N-50 cursor-pointer': !isDisabled,
        'text-N-400 cursor-auto': isDisabled,
    });
    const SelectedItemClasses = CN({
        'text-[#1D5BD7] bg-B-50': selectionType === 'multiple' && id === selectedItem,
    });
    const BorderBottomClasses = CN({
        'border-b-[1px] border-N-200': hasItemSeperator,
    });
    const DropdownClasse = CN(CommonClasses, DisableStateClasses, SelectedItemClasses, BorderBottomClasses);

    const handleSingleItemClick = (itemId?: number) => {
        if (selectionType === 'single' && !isDisabled) onClick?.(itemId ? itemId : !subItems ? id : undefined);
        if (handleItemOnClick && !isDisabled) handleItemOnClick(itemId ? itemId : !subItems ? id : undefined);
    };

    return (
        <span
            key={id}
            onClick={() => (subItems && subItems.length > 0 ? setIsExpanded(!isExpanded) : handleSingleItemClick())}
            className={DropdownClasse}
        >
            <div className="flex items-center gap-x-[8px] px-[16px]">
                {leadingNodeType && (
                    <div>
                        {leadingNodeType === 'icon' && icon && <i className={icon} />}
                        {leadingNodeType === 'avatar' && avatarProps && <Avatar {...avatarProps} />}
                    </div>
                )}
                <div className="flex items-center justify-between w-full">
                    {selectionType === 'single' ? (
                        <div className="w-full overflow-hidden">
                            {label}
                            {showSelectedCheck && id === selectedItem && (
                                <i className="ri-check-line right-4 absolute" />
                            )}
                            {subItems && subItems.length > 0 && (
                                <i
                                    className={`ri-arrow-down-s-line right-4 absolute transition-all ease-in-out duration-500 ${
                                        isExpanded ? '-rotate-180' : ''
                                    }`}
                                />
                            )}
                        </div>
                    ) : (
                        <div>
                            <CheckBox
                                onChange={() => onClick?.(id)}
                                defaultChecked={defaultChecked || false}
                                id={id}
                                label={label}
                                disabled={isDisabled}
                            />
                            <div className={subItems?.length === 0 ? 'hidden' : 'ml-[20px]'}>
                                {subItems?.map(subItem => (
                                    <CheckBox
                                        key={subItem.id}
                                        onChange={() => subItem.onClick?.(subItem.id)}
                                        defaultChecked={subItem.defaultChecked || false}
                                        id={parseFloat(`${id}.${subItem.id}`)}
                                        label={subItem.label}
                                        disabled={subItem.isDisabled}
                                        className="mt-[12px]"
                                    />
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {selectionType === 'single' && subItems && subItems.length > 0 && isExpanded && (
                <div className="w-full left-0 bottom-0 pt-2">
                    {subItems.map(subItem => (
                        <div
                            className="flex items-center gap-x-[8px] px-[32px] w-full hover:bg-N-100 cursor-pointer py-[8px]"
                            key={subItem.id}
                            onClick={e => {
                                e.stopPropagation();
                                handleSingleItemClick(subItem.id);
                            }}
                        >
                            {subItem.leadingNodeType && (
                                <div>
                                    {subItem.leadingNodeType === 'icon' && subItem.icon && (
                                        <i className={subItem.icon} />
                                    )}
                                    {subItem.leadingNodeType === 'avatar' && subItem.avatarProps && (
                                        <Avatar {...subItem.avatarProps} />
                                    )}
                                </div>
                            )}
                            <div>
                                {subItem.label}
                                {showSelectedCheck && subItem.id === selectedItem && (
                                    <i className="ri-check-line right-4 absolute" />
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </span>
    );
};
