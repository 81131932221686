import { MultiSelectableInput } from '@kaya/kaya-ui-design-system-pb';
import { useEffect, useState } from 'react';
import { MODEL_TYPES } from '../../../constants';

interface IModelMultiSelectProps {
    onChange: Function;
    width?: string;
    taglength?: number;
    selectedModels: any;
}

export const ModelMultiSelect = ({ onChange, width = '', taglength = 3, selectedModels }: IModelMultiSelectProps) => {
    const [currentModels, setCurrentModels] = useState<any[]>([]);

    const SELECTABLE_MODELS = MODEL_TYPES.flatMap(modelType => {
        const models = [];

        if (!modelType.models) {
            models.push({
                id: modelType.model,
                name: modelType.name,
            });
        }

        if (modelType.models) {
            const nestedModels = modelType.models.map(nestedModel => ({
                id: nestedModel.model,
                name: nestedModel.name,
            }));

            models.push(...nestedModels);
        }

        return models;
    });

    useEffect(() => {
        const filteredAndSortedModels = SELECTABLE_MODELS?.filter(model => selectedModels?.includes(model?.id))?.sort(
            (a, b) => a?.name?.localeCompare(b?.name)
        );
        setCurrentModels(filteredAndSortedModels || []);
    }, [selectedModels]);

    return (
        <MultiSelectableInput
            taglength={taglength}
            width={width}
            showTags
            selectedValues={currentModels}
            placeholder="Select Models"
            selectableItems={SELECTABLE_MODELS}
            onSelect={(model: any) => {
                onChange(model);
            }}
        />
    );
};
