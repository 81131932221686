import { ReactNode, useEffect, useRef, useState } from 'react';
import CN from 'classnames';
import './ExpandableDropdown.scss';
import { ExpandableDropdownItem, ExpandableDropdownItemProps } from './ExpandableDropdownItem';
import useMeasure from 'react-use-measure';
import { CheckBox, SearchInput } from '@kaya/kaya-ui-design-system-pb';

interface HeaderType {
    type?: 'title' | 'selection' | 'search';
    title?: string | ReactNode;
    handleHeaderAction?: () => void;
    placeholder?: string;
}

interface ExpandableDropdownProps {
    children?: string | ReactNode;
    defaultSelectedItem?: number | undefined;
    dropdownItems: ExpandableDropdownItemProps[];
    hasItemSeperator?: boolean;
    header?: HeaderType;
    onClick?: (id: number | undefined) => void;
    selectionType: 'single' | 'multiple';
    showSelectedCheck?: boolean;
    triggerAction: 'onClick' | 'onHover';
    wrapperClassName?: string;
    openningPosition?: 'above' | 'below';
    containerHeight?: number;
}

export const ExpandableDropdown = ({
    children,
    defaultSelectedItem,
    dropdownItems,
    hasItemSeperator,
    header,
    onClick,
    selectionType,
    showSelectedCheck = false,
    triggerAction,
    wrapperClassName,
    openningPosition,
    containerHeight,
}: ExpandableDropdownProps) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [selectedItem, setSelectedItem] = useState<number | undefined | null>(null);
    const [shouldOpenAbove, setShouldOpenAbove] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [measureRef, { height }] = useMeasure();

    const CommonClassNames = CN(
        'dropdown absolute w-max z-[50] rounded-[6px] inline-flex flex-col py-[8px] bg-white shadow-lg',
        {
            'top-auto bottom-full mb-2': shouldOpenAbove,
            'top-full mt-2 bottom-auto': !shouldOpenAbove,
        },
        wrapperClassName
    );
  
    useEffect(() => {
        if (isMenuOpen && dropdownRef.current) {
            const dropdownRect = dropdownRef.current.getBoundingClientRect();
            const windowHeight = containerHeight || window.innerHeight;
            
            const spaceBelow = windowHeight - dropdownRect.bottom;
            const spaceAbove = dropdownRect.top;
    
            if (openningPosition === 'above') {
                setShouldOpenAbove(true);
            } else if (openningPosition === 'below') {
                setShouldOpenAbove(false);
            } else {
                setShouldOpenAbove(spaceBelow < dropdownRect.height && spaceAbove > spaceBelow);
            }
        }
    }, [isMenuOpen, containerHeight]);
    
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, [dropdownRef]);

    // this useEfferct to set the default item selection onComponent moust
    useEffect(() => {
        setSelectedItem(defaultSelectedItem && defaultSelectedItem);
    }, []);

    // this function will handle the dropdownitem click
    const handleItemOnClick = (id: number | undefined) => {
        setSelectedItem(id);
        onClick?.(id);
        setTimeout(() => {
            if (id && selectionType === 'single') {
                setIsMenuOpen(false);
            }
        }, 50);
    };

    // onMouseOver, onClick and onMouseLeave functions
    const handleDropdownClick = () => {
        if (triggerAction === 'onClick') {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    const handleDropdownHover = () => {
        if (triggerAction === 'onHover') {
            setIsMenuOpen(true);
        }
    };

    const handleDropdownLeave = () => {
        if (triggerAction === 'onHover') {
            setTimeout(() => {
                setIsMenuOpen(false);
            }, 500);
        }
    };

    // this function render the dropdown header based on the header type
    const renderHeader = () => {
        if (!header) {
            return null;
        }

        const headerClassNames = 'px-[16px] pt-[4px] pb-[12px] border-b-[1px] border-N-200 text-sm';

        switch (header.type) {
            case 'title':
                return <div className={headerClassNames}>{header.title}</div>;
            case 'selection':
                return (
                    <div className={headerClassNames}>
                        <CheckBox label={header.title} id={5} />
                    </div>
                );
            case 'search':
                return (
                    <div className={headerClassNames}>
                        <SearchInput
                            iconBefore="ri-search-line"
                            onChange={() => {}}
                            placeholder={header.placeholder}
                            view="default"
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="relative inline-flex flex-col" ref={dropdownRef} onMouseOver={handleDropdownHover}>
            <span onClick={handleDropdownClick} className="inline-flex">
                {children}
            </span>
            {isMenuOpen && (
                <div ref={measureRef} className={CommonClassNames} onMouseLeave={handleDropdownLeave}>
                    <div onClick={header?.handleHeaderAction}>{renderHeader()}</div>
                    {dropdownItems &&
                        dropdownItems.map((dropdownItem: ExpandableDropdownItemProps) => {
                            return (
                                <ExpandableDropdownItem
                                    key={dropdownItem.id}
                                    {...dropdownItem}
                                    handleItemOnClick={handleItemOnClick}
                                    hasItemSeperator={hasItemSeperator}
                                    selectedItem={selectedItem}
                                    selectionType={selectionType}
                                    showSelectedCheck={showSelectedCheck}
                                />
                            );
                        })}
                </div>
            )}
        </div>
    );
};
