import { FeatureType, MessageAuthor } from '../__generated__/graphql';
import { SYSTEM_MODEL_TEMP_ID } from '../constants';
import { useChatContext } from '../contexts';
import { getCurrentTime, getFilteredModelsByUser, uniqueId } from '../utils';
import { ExpandableDropdownItemProps } from '@kaya/kaya-ui-design-system-pb/dist/src/components/atoms/ExpandableDropdown/ExpandableDropdownItem';

interface DropdownItemWithKey extends ExpandableDropdownItemProps {
    key: number;
}

const useChatHeader = () => {
    const {
        currentUserConfig,
        messages,
        settings,
        setMessages,
        setSettings,
        currentAppConfig,
        conversation,
        featureType,
    } = useChatContext();

    const title = conversation?.title || 'New Chat';
    const label = currentAppConfig?.title || FeatureType.General;
    const updatedDate = conversation?.updatedAt || new Date().toISOString();
    const date = updatedDate.split('T')[0];
    const modelTypes = getFilteredModelsByUser(currentUserConfig);
    const currentModelImage = modelTypes.flatMap(modelType =>
        modelType.type === 'category' && modelType.models
            ? modelType.models
            : modelType
    ).find(model => model.model === settings.model)?.imageURL;
    const description =
        currentAppConfig?.metadata.welcomeMessage ||
        `Welcome to ${label} KPL 🎉! Explore anything with our comprehensive knowledge base.`;
    const currentSelectedModel = modelTypes.find(model => model.model === settings.model)?.model;

    const findModelById = (id: number | undefined) => {
        for (const modelType of modelTypes) {
            // Check if the top-level model matches the id
            if ((modelTypes.indexOf(modelType) + 1) * 100 === id) {
                return modelType;
            }

            // Check nested models if this model type is a category with subItems
            if (modelType.type === 'category' && modelType.models) {
                const foundNestedModel = modelType.models.find(
                    (nestedModel, j) => (modelTypes.indexOf(modelType) + 1) * 100 + (j + 1) === id
                );

                if (foundNestedModel) {
                    return foundNestedModel;
                }
            }
        }

        // Return null if no model is found with the given id
        return null;
    };

    const handleModelChange = (id: number | undefined) => {
        const modelType = findModelById(id);

        const model = modelType?.model;
        const name = modelType?.name;

        if (model && name) {
            const content = name;
            const currentTime = getCurrentTime();

            setSettings(s => ({ ...s, model }));

            const modelChangeMessage = {
                id: `${SYSTEM_MODEL_TEMP_ID}_${uniqueId()}`,
                content: content,
                author: MessageAuthor.System,
                time: currentTime,
            };

            const lastMessage = messages[messages.length - 1];

            if (lastMessage?.id?.includes(SYSTEM_MODEL_TEMP_ID)) {
                lastMessage.content = content;
                lastMessage.time = currentTime;
                setMessages(prevMessages => [...prevMessages]);
            } else {
                setMessages(prevMessages => [...prevMessages, modelChangeMessage]);
            }
        }
    };

    const createDropdownItem = (
        id: number,
        key: number,
        label: string,
        imageURL: string,
        onClick: (model: any) => void,
        subItems?: ExpandableDropdownItemProps[]
    ): DropdownItemWithKey => {
        return {
            id,
            key,
            label,
            leadingNodeType: 'avatar',
            avatarProps: {
                size: 'md',
                type: 'image',
                imageURL,
            },
            onClick,
            ...(subItems && { subItems }),
        };
    }

    const dropDownModels: ExpandableDropdownItemProps[] = modelTypes.map((modelType, i) => {
        const { type, models, name, imageURL } = modelType;

        if (type === 'category' && models) {
            // Map the category item and include nested models as `subItems`
            const subItems = models.map((nestedModel, j) =>
                createDropdownItem(
                    (i + 1) * 100 + (j + 1),
                    (i + 1) * 100 + (j + 1),
                    nestedModel.name,
                    nestedModel.imageURL,
                    handleModelChange
                )
            );

            return createDropdownItem((i + 1) * 100, (i + 1) * 100, name, imageURL, handleModelChange, subItems);
        }

        // For top-level models without nested models
        return createDropdownItem((i + 1) * 100, (i + 1) * 100, name, imageURL, handleModelChange);
    });

    return {
        title,
        label,
        date,
        currentModelImage,
        modelTypes,
        dropDownModels,
        description,
        featureType,
        currentSelectedModel,
    };
};

export { useChatHeader };
