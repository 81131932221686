/* eslint-disable */
import { useMutation, useQuery } from '@apollo/client';
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import validator from 'validator';
import {
    CallReportOptions,
    ChatType,
    DocumentPersistenceType,
    FeatureType,
    MessageAuthor,
    MessageSettingsInput,
    MessageType,
    ModelType,
    Source,
} from '../__generated__/graphql';
import { FEATURE_MODEL_TEMP_ID, JIRA_OPTIONS, MAX_TEXT_LENGTH } from '../constants';
import {
    DELETE_TEMPORARY_DOCUMENTS,
    GET_APPLICATION_CONFIGURATIONS,
    GET_APPLICATION_USER_CONFIG_BY_EMAIL,
    UPSERT_USER,
} from '../graphql';
import { downloadMessagesAsPDF } from '../services';
import {
    gerCurrentDate,
    getCurrentTime,
    getDefaultSettings,
    getDefaultTabToDisplay,
    getFullScreenOption,
    logger,
    uniqueId,
} from '../utils';
import { useCustomKplContext } from './CustomKplContext';

export interface IJiraOptions {
    jiraUserEmail: string;
    jiraUserToken: string;
    jiraInstanceName: string;
    jiraProjectKey: string;
}

export interface IMessage {
    id: string;
    content: string;
    author: MessageAuthor;
    processing?: boolean;
    feedback?: boolean;
    time?: string;
    type?: MessageType;
    sources?: Source[];
    previousContent?: string;
    elements?: any;
}

export interface IChatbotUserDetails {
    id: string;
    imageUrl: string;
    name: string;
    sessionId: string;
    email: string;
    roles: string[];
}

export interface FeedbackData {
    technicalAssistance: number;
    employeeEngagement: number;
    dailyUsage: number;
    file: File | null;
}

export interface ModelListType {
    type: 'category' | 'model'; // Restrict type to 'category' or 'model'
    models?: ModelListType[]; // Optional, only present if type is 'category'
    name: string;
    model: ModelType;
    imageURL: string;
}

export interface AppConfigType {
    id: number;
    configKey: string;
    description: string;
    icon: string;
    enabled: boolean;
    chatType: ChatType;
    metadata: {
        webSearch: {
            engine: string;
            enabled: boolean;
            search_results: number;
        };
        dynamicPrompts: {
            enabled: boolean;
        };
        noLLM: {
            enabled: boolean;
        };
        welcomeMessage: string;
        showPreDefinedQuestions?: boolean;
        customWelcomeMessage: {
            enabled: boolean;
            message: string;
        };
        overridePredefinedQuestions: {
            enabled: boolean;
            questions: { key: string; value: string; id: number }[];
        };
        filter: Filter;
    };
    title: string;
}

export type Conversation = {
    id: string;
    title: string;
    feature: AppConfigType;
    updatedAt: string;
};

export interface AppUsersConfigType {
    email: string;
    features: string;
    models: string;
    override: boolean;
    metadata: {
        default: {
            feature: string;
            model: string;
            dynamicPrompts: boolean;
            webSearch: boolean;
            noLLM: boolean;
        };
    };
}

export enum FilterTypeTemp {
    $or = '$or',
    $and = '$and',
}

interface Filter {
    [key: string]: FilterCondition[] | undefined;
}

interface FilterCondition {
    client?: FilterValue;
    domain?: FilterValue;
}

interface FilterValue {
    $in: string[];
}

interface IChatContext {
    userDetails: IChatbotUserDetails;
    userFirstName: string;
    conversationId: string;
    setConversationId: React.Dispatch<React.SetStateAction<string>>;
    currentMessage: string;
    setCurrentMessage: React.Dispatch<React.SetStateAction<string>>;
    messages: IMessage[];
    setMessages: React.Dispatch<React.SetStateAction<IMessage[]>>;
    timesheets: any[];
    setTimesheets: React.Dispatch<React.SetStateAction<any[]>>;
    chatType: ChatType;
    setChatType: React.Dispatch<React.SetStateAction<ChatType>>;
    featureType: string;
    setFeatureType: React.Dispatch<React.SetStateAction<string>>;
    loading: boolean;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    fullscreen: boolean;
    setFullscreen: React.Dispatch<React.SetStateAction<boolean>>;
    timesheetFormData: any;
    setTimesheetFormData: React.Dispatch<React.SetStateAction<any>>;
    isValid: boolean;
    settings: MessageSettingsInput;
    setSettings: React.Dispatch<React.SetStateAction<MessageSettingsInput>>;
    handleAppConfigSelection: (config: AppConfigType) => void;
    handleNewChat: () => void;
    handleConversationSelection: (conversation: Conversation) => void;
    jiraOptions: IJiraOptions;
    setJiraOptions: React.Dispatch<React.SetStateAction<IJiraOptions>>;
    showJiraButtons: { business: boolean; technical: boolean };
    setShowJiraButtons: React.Dispatch<React.SetStateAction<{ business: boolean; technical: boolean }>>;
    streaming: boolean;
    lastMessageId: string;
    appConfig: AppConfigType[];
    currentUserConfig: AppUsersConfigType | null;
    currentAppConfig: AppConfigType | null;
    chatDocuments: File[];
    setChatDocuments: React.Dispatch<React.SetStateAction<File[]>>;
    documentPersistanceType: DocumentPersistenceType | null;
    setDocumentPersistanceType: React.Dispatch<React.SetStateAction<DocumentPersistenceType>>;
    cleanupState: () => void;
    callReportQuestions: CallReportOptions[];
    setCallReportQuestions: React.Dispatch<React.SetStateAction<CallReportOptions[]>>;
    technicalRequirementValue: string;
    setTechnicalRequirementValue: React.Dispatch<React.SetStateAction<string>>;
    conversation: Conversation | null;
    setConversation: React.Dispatch<React.SetStateAction<Conversation | null>>;
    handleExportToPDF: () => void;
    sanitizedMessage: (message: string) => string;
    isCurrentUserAdmin: boolean;
    refetchApplicationConfigs: () => void;
    isCurrentUserGuest: boolean;
    kplListLoading: boolean;
    isShowKplGuildLines: boolean;
    setIsShowKplGuildLines: Function;
    refetchCurrentUserConfig: Function;
}
interface IChatContextProvider {
    userDetails: IChatbotUserDetails;
    children: ReactNode;
}

const ChatContext = createContext<IChatContext>({
    userDetails: { id: '', imageUrl: '', name: '', sessionId: '', email: '', roles: [] },
    userFirstName: '',
    conversationId: '',
    setConversationId: () => {},
    currentMessage: '',
    setCurrentMessage: () => {},
    messages: [],
    setMessages: () => {},
    timesheets: [],
    setTimesheets: () => {},
    chatType: ChatType.Assistant,
    setChatType: () => {},
    featureType: FeatureType.General,
    setFeatureType: () => {},
    loading: false,
    setLoading: () => {},
    fullscreen: false,
    setFullscreen: () => {},
    timesheetFormData: { date: gerCurrentDate() },
    setTimesheetFormData: () => {},
    isValid: false,
    settings: {
        feature: '',
        model: '',
        type: '',
        options: { dynamicPrompts: false, webSearch: false, noLLM: false },
        hasTemporaryFiles: false,
    },
    setSettings: () => {},
    handleAppConfigSelection: () => {},
    handleNewChat: () => {},
    handleConversationSelection: () => {},
    jiraOptions: JIRA_OPTIONS,
    setJiraOptions: () => {},
    showJiraButtons: { business: false, technical: false },
    setShowJiraButtons: () => {},
    streaming: false,
    lastMessageId: '',
    appConfig: [],
    currentUserConfig: null,
    currentAppConfig: null,
    chatDocuments: [],
    setChatDocuments: () => {},
    documentPersistanceType: null,
    setDocumentPersistanceType: () => {},
    cleanupState: () => {},
    callReportQuestions: [],
    setCallReportQuestions: () => {},
    technicalRequirementValue: '',
    setTechnicalRequirementValue: () => {},
    conversation: null,
    setConversation: () => {},
    handleExportToPDF: () => {},
    sanitizedMessage(message: string): string {
        return message;
    },
    isCurrentUserAdmin: false,
    refetchApplicationConfigs: () => {},
    isCurrentUserGuest: false,
    kplListLoading: false,
    refetchCurrentUserConfig: () => {},
    isShowKplGuildLines: true,
    setIsShowKplGuildLines: () => {},
});

export const ChatContextProvider: React.FC<IChatContextProvider> = ({ userDetails, children }) => {
    const [upsertUser] = useMutation(UPSERT_USER);
    const [deleteTemporaryFiles] = useMutation(DELETE_TEMPORARY_DOCUMENTS);
    const [kplListLoading, setKplListLoading] = useState<boolean>(false);
    const { data: applicationConfigData, refetch } = useQuery(GET_APPLICATION_CONFIGURATIONS, {
        fetchPolicy: 'cache-first',
        variables: {
            enabled: true,
        },
    });
    const { data: applicationUserConfigData, refetch: refetchCurrentUserConfig } = useQuery(
        GET_APPLICATION_USER_CONFIG_BY_EMAIL,
        {
            fetchPolicy: 'cache-first',
            variables: {
                email: userDetails.email,
            },
        }
    );

    const appConfig = (applicationConfigData?.getApplicationConfig as AppConfigType[]) || [];
    const currentUserConfig = applicationUserConfigData?.getApplicationUserConfigByEmail as AppUsersConfigType;

    const defaultTab = getDefaultTabToDisplay(currentUserConfig);
    const defaultSettings = getDefaultSettings(currentUserConfig);

    const [callReportQuestions, setCallReportQuestions] = useState<CallReportOptions[]>([]);
    const [jiraOptions, setJiraOptions] = useState<IJiraOptions>(JIRA_OPTIONS);
    const [conversationId, setConversationId] = useState<string>(crypto.randomUUID());
    const [conversation, setConversation] = useState<Conversation | null>(null);
    const [currentMessage, setCurrentMessage] = useState('');
    const [messages, setMessages] = useState<any[]>([]);
    const [timesheets, setTimesheets] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [fullscreen, setFullscreen] = useState(getFullScreenOption());
    const [chatType, setChatType] = useState<ChatType>(ChatType.Assistant);
    const [featureType, setFeatureType] = useState<string>(defaultTab);
    const [technicalRequirementValue, setTechnicalRequirementValue] = useState('');
    const [settings, setSettings] = useState(defaultSettings);
    const [chatDocuments, setChatDocuments] = useState<File[]>([]);
    const [documentPersistanceType, setDocumentPersistanceType] = useState<DocumentPersistenceType>(
        DocumentPersistenceType.Temporary
    );
    const [timesheetFormData, setTimesheetFormData] = useState({
        date: gerCurrentDate(),
    });
    const [showJiraButtons, setShowJiraButtons] = useState({
        business: false,
        technical: false,
    });
    const [isShowKplGuildLines, setIsShowKplGuildLines] = useState<boolean>(false);

    const userFirstName = userDetails.name?.split(' ')?.[0] || '';
    const streaming = Boolean(messages?.slice(-1)[0]?.processing);
    const lastMessageId = messages?.slice(-1)[0]?.id || '';
    const isMessageProcessing = loading || streaming;
    const isValid = currentMessage.trim() !== '' && currentMessage?.length <= MAX_TEXT_LENGTH && !isMessageProcessing;
    const currentAppConfig = appConfig.find(config => config.configKey === featureType) || null;
    const isCurrentUserAdmin = userDetails.roles.includes('admin');
    const isCurrentUserGuest = userDetails.roles.includes('bernie_guest');

    useEffect(() => {
        setIsShowKplGuildLines(currentAppConfig?.metadata?.showPreDefinedQuestions ?? true);
    }, [currentAppConfig?.configKey]);

    const deleteTemporaryDocuments = async (conversationId: string) => {
        try {
            await deleteTemporaryFiles({
                variables: {
                    input: {
                        conversationId,
                        userId: userDetails.id,
                    },
                },
            });
        } catch (error) {
            logger.log(error);
        }
    };

    const refetchApplicationConfigs = () => {
        setKplListLoading(true);
        refetch().finally(() => {
            setKplListLoading(false);
        });
    };

    const resetDocumentHandlingState = () => {
        setDocumentPersistanceType(DocumentPersistenceType.Temporary);
        setChatDocuments([]);
    };

    const cleanupState = () => {
        deleteTemporaryDocuments(conversationId);
        resetDocumentHandlingState();
    };

    const handleExportToPDF = () => {
        downloadMessagesAsPDF(messages, conversationId, userDetails);
    };

    const createNewConversation = () => {
        const newConversationId = crypto.randomUUID();
        setConversationId(newConversationId);
        setConversation(null);
        setLoading(false);
        logger.log('New conversation id is set: ' + newConversationId);
    };

    const setupNewConversation = (configKey: string, chatType: ChatType) => {
        setFeatureType(configKey);
        setChatType(chatType);
        setSettings((s: any) => ({
            ...s,
            feature: configKey,
            hasTemporaryFiles: false,
            options: { dynamicPrompts: false, webSearch: false, noLLM: false },
        }));
        resetDocumentHandlingState();
        createNewConversation();
    };

    const switchToNewKPL = (selectedConfig: AppConfigType) => {
        setFeatureType(selectedConfig?.configKey);
        setChatType(selectedConfig?.chatType);
        setSettings((s: any) => ({
            ...s,
            hasTemporaryFiles: false,
            feature: selectedConfig?.configKey,
        }));

        if (currentAppConfig?.configKey !== selectedConfig?.configKey && messages.length > 0) {
            setMessages(messages => {
                const filteredMessages = messages.filter(
                    message => message.type !== MessageType.TimesheetInput && message.type !== MessageType.DocumentInput
                );

                const currentTime = getCurrentTime();
                const hasFeatureChangeInput = messages[messages.length - 1]?.id.includes(FEATURE_MODEL_TEMP_ID);

                const updatedMessages = hasFeatureChangeInput ? filteredMessages.slice(0, -1) : filteredMessages;

                const modelChangeMessage = {
                    id: `${FEATURE_MODEL_TEMP_ID}_${uniqueId()}`,
                    content: selectedConfig.title,
                    author: MessageAuthor.System,
                    time: currentTime,
                };

                return [...updatedMessages, modelChangeMessage];
            });
        }
    };

    const handleNewChat = () => {
        const key = currentAppConfig ? currentAppConfig.configKey : FeatureType.General;
        const chatType = currentAppConfig ? currentAppConfig.chatType : ChatType.Assistant;
        setupNewConversation(key, chatType);
    };

    const handleConversationSelection = (conversation: Conversation) => {
        const featureType = conversation.feature.configKey || FeatureType.General;

        resetDocumentHandlingState();
        setConversation(conversation);
        setConversationId(conversation.id);
        setSettings((s: any) => ({ ...s, feature: featureType }));
        setFeatureType(featureType);
    };

    const sanitizedMessage = (message: string) => {
        try {
            return validator.escape(message);
        } catch (error) {
            return 'Your input contains invalid characters. Please try again.';
        }
    };

    const handleAppConfigSelection = (selectedConfig: AppConfigType) => {
        if (chatType !== ChatType.Assistant) {
            createNewConversation();
        }

        switch (selectedConfig?.configKey) {
            case FeatureType.ChatWithDocuments:
                if (
                    currentAppConfig?.chatType !== ChatType.Assistant ||
                    currentAppConfig?.configKey === FeatureType.General
                ) {
                    toast.error('Please select a KPL to proceed!');
                    return;
                } else if (
                    currentAppConfig?.configKey === FeatureType.KayaTaskInsights ||
                    currentAppConfig?.configKey === FeatureType.SonicTaskInsights
                ) {
                    toast.error('Document upload is not supported for task tracking KPLs!');
                    return;
                }

                setMessages(messages => {
                    const currentType = MessageType.DocumentInput;
                    const hasCurrentInput = messages.some(message => message.type === currentType);

                    if (!hasCurrentInput) {
                        return [
                            ...messages,
                            {
                                id: crypto.randomUUID(),
                                type: currentType,
                                author: MessageAuthor.User,
                                content: 'Upload your document here.',
                            },
                        ];
                    }

                    return messages;
                });

                break;

            case FeatureType.AddTimesheet:
                if (
                    currentAppConfig?.configKey !== FeatureType.KayaTaskInsights &&
                    currentAppConfig?.configKey !== FeatureType.SonicTaskInsights
                ) {
                    toast.error('Timesheet is not supported for this KPL!');
                    return;
                }
                cleanupState();
                setMessages(messages => {
                    const currentType = MessageType.TimesheetInput;
                    const hasCurrentInput = messages[messages.length - 1]?.type === currentType;

                    if (!hasCurrentInput) {
                        return [
                            ...messages,
                            {
                                id: crypto.randomUUID(),
                                type: currentType,
                                author: MessageAuthor.User,
                                content: 'Upload your task sheet here.',
                            },
                        ];
                    }

                    return messages;
                });

                break;

            default:
                cleanupState();
                switchToNewKPL(selectedConfig);
                break;
        }
    };

    useEffect(() => {
        const getUserId = async () => {
            const { id: userId } = userDetails;

            if (userId) {
                upsertUser({ variables: { input: userDetails } });
            }
        };

        if (userDetails) getUserId();
    }, [userDetails]);

    useEffect(() => {
        if (currentUserConfig) {
            const defaultTab = getDefaultTabToDisplay(currentUserConfig);
            const defaultSettings = getDefaultSettings(currentUserConfig);

            setFeatureType(defaultTab);
            setSettings(defaultSettings);
        }
    }, [currentUserConfig]);

    return (
        <ChatContext.Provider
            value={{
                userDetails,
                userFirstName,
                conversationId,
                setConversationId,
                currentMessage,
                setCurrentMessage,
                messages,
                setMessages,
                timesheets,
                setTimesheets,
                chatType,
                setChatType,
                featureType,
                setFeatureType,
                loading,
                setLoading,
                fullscreen,
                setFullscreen,
                timesheetFormData,
                setTimesheetFormData,
                isValid,
                settings,
                setSettings,
                handleAppConfigSelection,
                handleNewChat,
                handleConversationSelection,
                jiraOptions,
                setJiraOptions,
                showJiraButtons,
                setShowJiraButtons,
                streaming,
                lastMessageId,
                appConfig,
                currentUserConfig,
                currentAppConfig,
                chatDocuments,
                setChatDocuments,
                documentPersistanceType,
                setDocumentPersistanceType,
                cleanupState,
                callReportQuestions,
                setCallReportQuestions,
                technicalRequirementValue,
                setTechnicalRequirementValue,
                conversation,
                setConversation,
                handleExportToPDF,
                sanitizedMessage,
                isCurrentUserAdmin,
                refetchApplicationConfigs,
                isCurrentUserGuest,
                kplListLoading,
                refetchCurrentUserConfig,
                isShowKplGuildLines,
                setIsShowKplGuildLines,
            }}
        >
            {children}
        </ChatContext.Provider>
    );
};

export const useChatContext = (): IChatContext => {
    const context = useContext(ChatContext);

    if (!context) {
        throw new Error('useChatContext must be used within a ChatContextProvider');
    }

    return context;
};
